// src/components/SEO.jsx
import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

// Meta-Daten aus JSON-Dateien importieren
import metaEn from '../meta/meta-en.json';
import metaDe from '../meta/meta-de.json';

// Wenn du später weitere Sprachen (z. B. fr, es) hinzufügst,
// einfach das Objekt erweitern oder JSON-Dateien dynamisch laden.
const metaByLang = {
  en: metaEn,
  de: metaDe,
};

// Optionale globale Fallback-Meta, falls Route nicht im JSON steht:
const fallbackMeta = {
  title: 'TryOnAI – Next-Generation Virtual Fitting',
  description: 'Experience AI-powered virtual try-ons for a seamless online shopping experience.',
  keywords: 'virtual try-on, AI clothing, online fashion'
};

const SEO = () => {
  const { i18n } = useTranslation();
  const location = useLocation();

  // Aktuelle Sprache (z. B. "en" oder "de")
  const currentLang = i18n.language || 'en';

  // Aktuellen Pfad ermitteln, z. B. "/contact".
  // Da deine Routen "/en/contact" oder "/de/contact" sind,
  // entfernen wir das führende /en oder /de, um auf "/contact" zu kommen.
  const rawPath = location.pathname || '/';
  const normalizedPath = rawPath.replace(/^\/(en|de)/, '') || '/';

  // Hole dir die passenden Meta-Daten für die aktuelle Sprache
  const metaDataForLang = metaByLang[currentLang] || {};
  const meta = metaDataForLang[normalizedPath];

  // Falls die Route nicht in der JSON steht, fallback verwenden
  const title = meta?.title || fallbackMeta.title;
  const description = meta?.description || fallbackMeta.description;
  const keywords = meta?.keywords || fallbackMeta.keywords;

  // Dynamische Liste aller unterstützten Sprachen:
  const allLanguages = Object.keys(metaByLang); // z. B. ["en", "de"]

  return (
    <Helmet>
      {/* Grundlegende Meta-Tags */}
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />


      {/* Dynamische hreflang-Generierung für alle definierten Sprachen */}
      {allLanguages.map((lang) => (
        <link
          key={lang}
          rel="alternate"
          hrefLang={lang}
          href={`https://virtual-tryon-ai.com/${lang}${normalizedPath}`}
        />
      ))}
      {/* Wenn du zusätzlich eine x-default-Sprachversion willst: 
         <link rel="alternate" hrefLang="x-default" href="https://virtual-tryon-ai.com/" /> 
      */}
    </Helmet>
  );
};

export default SEO;
