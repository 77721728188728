import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import React, { Suspense, lazy, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useParams,
  useNavigate,
  useLocation,
  Navigate
} from 'react-router-dom';
import Header from './components/Header';
import AuthProvider from './components/AuthContext';
import CookieBanner from './components/CookieBanner';
import ScrollToTop from './components/ScrollToTop';
import SEO from './components/SEO'; // Importiere deine SEO-Komponente
import './App.css';

// i18n-Initialisierung
i18n
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    debug: true,
    detection: {
      order: ['navigator', 'path'],
      lookupFromPathIndex: 0,
      caches: []
    },
    interpolation: {
      escapeValue: false,
    },
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
    },
  });

// Lazy load Components
const LandingPage = lazy(() => import('./components/LandingPage'));
const TryOnFeature = lazy(() => import('./components/TryOnFeature'));
const GetTokensPage = lazy(() => import('./components/GetTokensPage'));
const ContactPage = lazy(() => import('./components/ContactPage'));
const TermsOfUse = lazy(() => import('./components/TermsOfUse'));
const LegalDisclosure = lazy(() => import('./components/LegalDisclosure'));
const PrivacyPolicy = lazy(() => import('./components/PrivacyPolicy'));
const LoginForm = lazy(() => import('./components/LoginForm'));
const SignUp = lazy(() => import('./components/SignUp'));
const AccountPage = lazy(() => import('./components/AccountPage'));
const LearnMorePage = lazy(() => import('./components/LearnMorePage'));
const VerifyEmail = lazy(() => import('./components/VerifyEmail'));
const VerifyEmailNotice = lazy(() => import('./components/VerifyEmailNotice'));
const RequestPasswordReset = lazy(() => import('./components/RequestPasswordReset'));
const ResetPassword = lazy(() => import('./components/ResetPassword'));
const ComingSoonPage = lazy(() => import('./components/ComingSoonPage'));
const PrivacyPancat = lazy(() => import('./components/PrivacyPancat'));
const LandingPage_Pancat = lazy(() => import('./components/LandingPage_Pancat'));
const AboutPage = lazy(() => import('./components/AboutPage'));
const AdminPage = lazy(() => import('./components/AdminPage'));
const BulkMailAdmin = lazy(() => import('./components/BulkMailAdmin'));
const AdminDashboard = lazy(() => import('./components/AdminDashboard'));

/* 
  Component, der bei Aufruf der Wurzel-URL ("/") 
  die Browser-Sprache ausliest und den Nutzer auf die entsprechende Sprachroute weiterleitet.
*/
const LanguageRedirector = () => {
  const navigate = useNavigate();
  const location = useLocation();
  
  useEffect(() => {
    // Pfad in Segmente aufteilen (Beispiel: "/about" -> ["", "about"])
    const pathParts = location.pathname.split('/');
    // Falls das erste Segment (nach dem führenden Slash) bereits "en" oder "de" ist, keine Umleitung
    if (['en', 'de'].includes(pathParts[1])) {
      return;
    }
    const userLang = window.navigator.language || window.navigator.userLanguage;
    const lang = userLang.toLowerCase().startsWith('de') ? 'de' : 'en';
    navigate(`/${lang}${location.pathname}${location.search}`, { replace: true });
  }, [navigate, location.pathname]);
  
  return null;
};

/*
  Wrapper, der den Sprachparameter aus der URL (/:lng) ausliest und i18next aktualisiert.
  Hier werden auch die SEO-Metatags gesetzt.
*/
const LanguageRoutes = () => {
  const { lng } = useParams();
  useEffect(() => {
    if (lng) {
      i18n.changeLanguage(lng);
    }
  }, [lng]);
  
  return (
    <>
      <SEO />
      <Header />
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="tryon" element={<TryOnFeature />} />
        <Route path="get-tokens" element={<GetTokensPage />} />
        <Route path="login" element={<LoginForm />} />
        <Route path="signup" element={<SignUp />} />
        <Route path="contact" element={<ContactPage />} />
        <Route path="termsofuse" element={<TermsOfUse />} />
        <Route path="legaldisclosure" element={<LegalDisclosure />} />
        <Route path="privacypolicy" element={<PrivacyPolicy />} />
        <Route path="account" element={<AccountPage />} />
        <Route path="learn-more" element={<LearnMorePage />} />
        <Route path="verify-email" element={<VerifyEmail />} />
        <Route path="verify-email-notice" element={<VerifyEmailNotice />} />
        <Route path="request-password-reset" element={<RequestPasswordReset />} />
        <Route path="reset-password" element={<ResetPassword />} />
        <Route path="privacypancat" element={<PrivacyPancat />} />
        <Route path="about" element={<AboutPage />} />
        <Route path="terms-of-use" element={<TermsOfUse />} />
        <Route path="/:lng(en|de)/*" element={<LanguageRoutes />} />
      </Routes>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          {/* Routen mit gültigem Sprachcode */}
          <Route path="/:lng(en|de)/*" element={<LanguageRoutes />} />
          {/* Alle anderen Routen werden umgeleitet */}
          <Route path="*" element={<LanguageRedirector />} />
        </Routes>
      </Suspense>
    </>
  );
};

const App = () => {
  return (
    <AuthProvider>
      <Router>
        <ScrollToTop />
        <CookieBanner />
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            {/* Wurzel-URL: Sprache wird erkannt und weitergeleitet */}
            <Route path="/" element={<LanguageRedirector />} />
            {/* Sprachspezifische Routen: /en/... oder /de/... */}
            <Route path="/:lng/*" element={<LanguageRoutes />} />
            {/* Fallback */}
            <Route path="*" element={<Navigate to="/" />} />
            <Route path="/admin" element={<AdminPage />} />
            <Route path="/admin/mail" element={<BulkMailAdmin />} />
            <Route path="/admin/dashboard" element={<AdminDashboard />} />
          </Routes>
        </Suspense>
      </Router>
    </AuthProvider>
  );
};

export default App;
