// CookieBanner.js
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const CookieBanner = () => {
  const [isVisible, setIsVisible] = useState(false);
  const { t } = useTranslation();


  useEffect(() => {
    const isAccepted = localStorage.getItem('cookiesAccepted');
    if (!isAccepted) {
      setIsVisible(true);
    }
  }, []);

  const acceptCookies = () => {
    localStorage.setItem('cookiesAccepted', true);
    setIsVisible(false);
  };

  if (!isVisible) {
    return null;
  }

  return (
    <div className="cookie-banner">
      <p>
        {t('cookieBanner.text1')} <Link to="/termsofuse">{t('cookieBanner.text2')}</Link>.
      </p>
      <button onClick={acceptCookies}>{t('cookieBanner.accept')}</button>
    </div>
  );
};

export default CookieBanner;
