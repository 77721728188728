// AuthContext.js
import React, { createContext, useState, useEffect } from 'react';
import Cookies from 'js-cookie';

export const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);
  const [isFirstLogin, setIsFirstLogin] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false);

  useEffect(() => {
    const token = Cookies.get('token');
    const userInfo = Cookies.get('user');
    if (token && userInfo && userInfo !== "undefined") {
      try {
        const parsedUserInfo = JSON.parse(userInfo);
        if (typeof parsedUserInfo.isAdmin === 'undefined') {
          parsedUserInfo.isAdmin = parsedUserInfo.id === 21;
        }
        setIsAuthenticated(true);
        setUser(parsedUserInfo);

        if (!localStorage.getItem('hasLoggedInBefore')) {
          setIsFirstLogin(true);
          localStorage.setItem('hasLoggedInBefore', 'true');
        } else {
          setIsFirstLogin(false);
        }
      } catch (error) {
        console.error('Error parsing the user information:', error);
        setIsAuthenticated(false);
        setUser(null);
      }
    } else {
      setIsAuthenticated(false);
      setUser(null);
    }
  }, []);

  const login = (token, userInfo) => {
    if (typeof userInfo.isAdmin === 'undefined') {
      userInfo.isAdmin = userInfo.id === 21;
    }
    Cookies.set('token', token, { expires: 1 });
    Cookies.set('user', JSON.stringify(userInfo), { expires: 1 });
    setIsAuthenticated(true);
    setUser(userInfo);
    setOtpVerified(false);

    if (!localStorage.getItem('hasLoggedInBefore')) {
      setIsFirstLogin(true);
      localStorage.setItem('hasLoggedInBefore', 'true');
    } else {
      setIsFirstLogin(false);
    }
  };

  const logout = () => {
    Cookies.remove('token');
    Cookies.remove('user');
    setIsAuthenticated(false);
    setUser(null);
    setOtpVerified(false);
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        user,
        setUser, // Hinzugefügt!
        otpVerified,
        setOtpVerified,
        login,
        logout,
        isFirstLogin,
        setIsFirstLogin
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
